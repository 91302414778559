import Button = require("Everlaw/UI/Button");
import Dom = require("Everlaw/Dom");

/**
 * This file manages the creation/showing/hiding of window overlays.
 *
 * - There are different styles of overlay, each with their own "show" function below. With the
 *   exception of the transparent overlay, all overlays can display an optional message and an
 *   optional button to cancel the operation in progress.
 *
 * - Each overlay style is created the first time it is needed, and then reused.
 *
 * - Only one overlay can be visible at a time, so calling a "show" function will remove any
 *   existing overlay in addition to showing the new one.
 *
 * - Be careful when specifying document.body as the overlay parent. If any intervening (and
 *   possibly unexpected) dialog requires attention while the overlay is displayed (a UI.ok error
 *   response from the server, a login timeout dialog, etc.), the dialog will appear underneath the
 *   overlay and the page becomes unresponsive, requiring the user to reload it. Instead, it's
 *   recommended to place the overlay on the main body div for the page, so that dialogs will appear
 *   on top of the overlay.
 */

const overlays: { [style: string]: Overlay } = {};

class Overlay {
    private overlay: HTMLElement;
    private messageNode: HTMLElement;
    private button: Button;
    private onCancel: () => void;
    private parent: HTMLElement;
    constructor(params: { cssClass?: string; content?: Dom.Content } = {}) {
        const overlayContent = [(this.messageNode = Dom.div())];
        params.content && overlayContent.push(Dom.div(params.content));
        // TODO: Convert to flex and make it only cover the parent element
        this.overlay = Dom.table(
            { class: "overlay " + (params.cssClass || "") },
            Dom.tr(
                { style: { height: "100%" } },
                Dom.td(
                    {
                        style: {
                            verticalAlign: "middle",
                            height: "100%",
                            color: "white",
                            fontSize: "18px",
                        },
                    },
                    overlayContent,
                ),
            ),
            Dom.tr(
                Dom.td(
                    { style: { padding: "16px", fontSize: "16px" } },
                    Dom.node(
                        (this.button = new Button({
                            label: "Cancel",
                            class: "important safe",
                            onClick: () => {
                                this.onCancel();
                                hide();
                            },
                        })),
                    ),
                ),
            ),
        );
    }
    show(parent: HTMLElement, message: Dom.Content, onCancel: () => void) {
        this.setMessage(message);
        if (onCancel) {
            Dom.show(this.button);
            this.onCancel = onCancel;
        } else {
            Dom.hide(this.button);
        }
        this.parent = parent || document.body;
        Dom.place(this.overlay, this.parent);
    }
    hide() {
        try {
            this.parent.removeChild(this.overlay);
        } catch (e) {
            // in case it has already been removed
        }
    }
    setMessage(message?: Dom.Content) {
        // Don't set messages on the transparent overlay.
        if (message && this !== overlays["Transparent"]) {
            Dom.show(this.messageNode);
            Dom.setContent(this.messageNode, message);
        } else {
            Dom.hide(this.messageNode);
        }
    }
}

let currentOverlay: Overlay | null = null;

const TRANSPARENT = "Transparent";
const BASIC = "Basic";
const SPINNER = "Spinner";
const LOADING_SPINNER = "LoadingSpinner";

function show(
    overlay: Overlay,
    parent: HTMLElement,
    message?: Dom.Content,
    onCancel: () => void = () => {},
) {
    overlay.show(parent, message, onCancel);
    if (currentOverlay && currentOverlay !== overlay) {
        currentOverlay.hide();
    }
    currentOverlay = overlay;
}

/**
 * "Show" a transparent overlay. ("Show" is in quotes because it won't actually be visible.) This is
 * for when you want to block user input without changing the look of the page.
 */
export function showTransparent(parent: HTMLElement) {
    if (!overlays[TRANSPARENT]) {
        overlays[TRANSPARENT] = new Overlay({ cssClass: "transparent" });
    }
    show(overlays[TRANSPARENT], parent);
}

/**
 * Show a basic (empty) overlay with an optional message and an optional "cancel" button.
 */
export function showBasic(parent: HTMLElement, message?: Dom.Content, onCancel?: () => void) {
    if (!overlays[BASIC]) {
        overlays[BASIC] = new Overlay();
    }
    show(overlays[BASIC], parent, message, onCancel);
}

/**
 * Show an overlay containing a spinner, with an optional message and an optional "cancel" button.
 */
export function showSpinner(parent: HTMLElement, message?: Dom.Content, onCancel?: () => void) {
    if (!overlays[SPINNER]) {
        overlays[SPINNER] = new Overlay({
            content: Dom.img({
                src: "/images/spinner.gif",
                role: "progressbar",
                alt: "loading",
                style: {
                    width: "50px",
                    height: "50px",
                },
            }),
        });
    }
    show(overlays[SPINNER], parent, message, onCancel);
}

/**
 * Show an overlay containing a spinner surrounding the word "Loading", with an optional message
 * and an optional "cancel" button.
 */
export function showLoadingSpinner(
    parent: HTMLElement,
    message?: Dom.Content,
    onCancel?: () => void,
) {
    if (!overlays[LOADING_SPINNER]) {
        overlays[LOADING_SPINNER] = new Overlay({
            content: Dom.img({
                src: "/images/loading-spinner.gif",
                role: "progressbar",
                alt: "loading",
                style: {
                    width: "100px",
                    height: "100px",
                },
            }),
        });
    }
    show(overlays[LOADING_SPINNER], parent, message, onCancel);
}

/*
 * Set the message on the currently displayed overlay. This is a no-op if there is no overlay
 * showing or the showed overlay is transparent.
 */
export function setMessage(message: Dom.Content) {
    currentOverlay && currentOverlay.setMessage(message);
}

/*
 * Hide the currently displayed overlay. This is a no-op if no overlay is showing.
 */
export function hide() {
    if (currentOverlay) {
        currentOverlay.hide();
        currentOverlay = null;
    }
}
